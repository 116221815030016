<script>
import {
  ArrowUpIcon,
  ChevronRightIcon,
  SmartphoneIcon,
  VideoIcon
} from "vue-feather-icons";
import { Carousel, Slide } from "vue-carousel";

import Navbar from "@/components/navbar";
import Footer from "@/components/footer";
/**
 * Index-email-inbox component
 */
export default {
  data() {
    return {
      features: [
        {
          icon: "uil uil-comment-verify",
          title: "Manage your agency bookings in one place",
          text:
            "Say goodbye to the backward way of social media groups and email order. Reduce missing orders and wrong orders.",
        },
        {
          icon: "uil uil-image",
          title: "Set up products, price & availability",
          text:
            "Easily create all of your products and manage the different pricing options and start times. Your availability will sync across all of your sales channels ensuring you're never overbooked.",
        },
        {
          icon: "uil uil-video",
          title: "Distribute to reseller partners",
          text:
            "Use TREEP Agent Portal, You can sell through local, regional and global partners easily.",
        },
        {
          icon: "uil uil-database-alt",
          title: "Sell online and take payments",
          text:
            "Use TREEP Agent Portal to start selling online. Use one of our many integrated payment providers to take bookings and payments anytime, anywhere.",
        },
        {
          icon: "uil uil-users-alt",
          title: "Run your day-to-day operations",
          text:
            "Oversee all your upcoming bookings and passenger info, automatically pass it to your guides and drivers. Collaborate with all depatments in TREEP.",
        },
        {
          icon: "uil uil-apps",
          title: "See reporting in real time",
          text:
            "Run detailed reports on demand to quickly see your total booking revenue and find out which sales channels are performing best. Identify trends and opportunities for you to grow your bookings.",
        },
      ],
    };
  },
  components: {
    Navbar,
    Footer,
    Carousel,
    Slide,
    VideoIcon,
    ArrowUpIcon,
    ChevronRightIcon,
    SmartphoneIcon,
  },
};
</script>

<template>
  <div>
    <Navbar />

    <!-- Hero Start -->
    <section
      class="bg-home d-flex align-items-center"
      style="height: auto"
      id="home"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center mt-0 mt-md-5 pt-0 pt-md-5">
            <div class="title-heading margin-top-100">
              <h1 class="heading font-weight-bold mb-3">
                Agent Portal <br />
                Empower your partners resell activities with ease
              </h1>
              <p class="para-desc mx-auto text-muted">
                Travel agencies offer vital partnerships for any tour and activity business, and TREEP lets you take them further. TREEP Agent Portal is a best choice  for travel agents booking on behalf of customers.
              </p>
              <div class="mt-4 pt-2">
                <a href="https://treep.cc/demo/?channelType=1" class="btn btn-primary" target="_blank"
                  >Book a meeting<i class="mdi mdi-chevron-right"></i
                ></a>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-lg-10">
                <div class="home-dashboard">
                  <img
                    src="images/agent/1.jpg"
                    alt=""
                    class="img-fluid rounded-md shadow-lg"
                    style="z-index: -1"
                  />
                </div>
              </div>
            </div>
            <!--end row-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Partners start -->
    <section class="section py-4 bg-light">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="section-title text-center mb-4 pb-2">
              <h4 class="title mb-4">How Agent Portal works</h4>
              <p class="text-muted para-desc mb-0 mx-auto">
                The easiest way to start and grow your travel distribute business.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div
            class="col-lg-4 col-md-6 mt-5"
            v-for="(data, index) of features"
            :key="index"
          >
            <div class="media features feature-clean" style="height: 210px">
              <div class="icons text-primary text-center mx-auto">
                <i :class="`${data.icon} d-block rounded h3 mb-0`"></i>
              </div>
              <div class="content ml-4">
                <h5 class="mb-1">
                  <a href="javascript:void(0)" class="text-dark">{{
                    data.title
                  }}</a>
                </h5>
                <p class="text-muted mb-0">
                  {{ data.text }}
                </p>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Partners End -->

    <!-- Start -->

    <section class="section">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6">
            <img src="images/agent/2.jpg" class="img-fluid" alt="" />
          </div>
          <!--end col-->

          <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div class="section-title ml-lg-5">
              <h4 class="title mb-4">
                Agent Portal <br />
                Designed for the travel industry
              </h4>
              <p class="text-muted">
                TREEP Agent Portal allows you to customize product range that displayed to your agencies. Trace exsiting bookings will be easier than ever.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->

      <div class="container mt-100 mt-60">
        <div class="row align-items-center">
          <div class="col-md-6 order-1 order-md-2">
            <img src="images/template/inbox-cleanup.svg" alt="" />
          </div>
          <!--end col-->

          <div class="col-md-6 order-2 order-md-1">
            <div class="section-title ml-lg-5">
              <h4 class="title mb-4">
                Agent API access <br />
                For agencies with their own fully customized website
              </h4>
              <p class="text-muted">
                TREEP Agent API allows resellers to access products, availability, pricing and content from you, send booking and cancellation request to TREEP automatically.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <section class="section bg-light">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6">
              <div class="section-title text-md-left text-center">
                  <h4 class="title mb-4">Why is Agent Portal favored</h4>
                  <p class="text-muted mb-0 para-desc">Significant increase of your agency bookings baes on real statics.</p>
                  <ul class="list-unstyled text-muted mt-4 mb-0">
                      <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uil uil-check-circle align-middle"></i></span>More convenient.</li>
                      <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uil uil-check-circle align-middle"></i></span>More satisfied.</li>
                      <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uil uil-check-circle align-middle"></i></span>More bookings.</li>
                  </ul>
              </div>
            </div>
            <!--end col-->

          <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div class="faq-content">
                  <div class="accordion" id="accordionExampleone">
                      <b-card no-body class="mb-2 rounded border-bottom">
                          <b-card-header class="border-0 bg-light p-3 pr-5">
                              <a class="faq position-relative text-dark" v-b-toggle.accordion-1 href="javascript: void(0);">
                                  <h6 class="title mb-0"> Manage your products </h6>
                              </a>
                          </b-card-header>
                          <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                              <b-card-body class="px-2 py-4">
                                  <p class="text-muted mb-0 faq-ans">Keep all of your products in one place regardless of product type and availability.</p>
                              </b-card-body>
                          </b-collapse>
                      </b-card>
                      <b-card no-body class="mb-2 rounded border-bottom">
                          <b-card-header class="border-0 bg-light p-3 pr-5">
                              <a class="faq position-relative text-dark" v-b-toggle.accordion-2 href="javascript: void(0);">
                                  <h6 class="title mb-0">Check availability</h6>
                              </a>
                          </b-card-header>
                          <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                              <b-card-body class="px-2 py-4">
                                  <p class="text-muted mb-0 faq-ans">Get accurate availability which automatically update across Agent Portal in real-time.</p>
                              </b-card-body>
                          </b-collapse>
                      </b-card>
                      <b-card no-body class="mb-2 rounded border-bottom">
                          <b-card-header class="border-0 bg-light p-3 pr-5">
                              <a class="faq position-relative text-dark" v-b-toggle.accordion-3 href="javascript: void(0);">
                                  <h6 class="title mb-0"> Download voucher and invoice</h6>
                              </a>
                          </b-card-header>
                          <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                              <b-card-body class="px-2 py-4">
                                  <p class="text-muted mb-0 faq-ans">Free you from communication, agencies can easily download vouchers and invoices online anytime, anywhere.</p>
                              </b-card-body>
                          </b-collapse>
                      </b-card>

                      <b-card no-body class="mb-2 rounded border-bottom">
                          <b-card-header class="border-0 bg-light p-3 pr-5">
                              <a class="faq position-relative text-dark" v-b-toggle.accordion-4 href="javascript: void(0);">
                                  <h6 class="title mb-0"> Balance and credit enquiry </h6>
                              </a>
                          </b-card-header>
                          <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                              <b-card-body class="px-2 py-4">
                                  <p class="text-muted mb-0 faq-ans">Record balance and credit online, 1 click check your bills with suppliers.</p>
                              </b-card-body>
                          </b-collapse>
                      </b-card>

                      <b-card no-body class="mb-2 rounded border-bottom">
                          <b-card-header class="border-0 bg-light p-3 pr-5">
                              <a class="faq position-relative text-dark" v-b-toggle.accordion-5 href="javascript: void(0);">
                                  <h6 class="title mb-0"> Reports and data </h6>
                              </a>
                          </b-card-header>
                          <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
                              <b-card-body class="px-2 py-4">
                                  <p class="text-muted mb-0 faq-ans">Export reports according to booking date and travel date, making monthly financial verification with ease.</p>
                              </b-card-body>
                          </b-collapse>
                      </b-card>
                  </div>
              </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <div class="container mt-100 mt-60">
            <div class="rounded bg-primary p-lg-5 p-4">
                <div class="row align-items-end">
                    <div class="col-md-8">
                        <div class="section-title text-md-left text-center">
                            <h4 class="title mb-3 text-white title-dark">More bookings, less work</h4>
                            <p class="text-white-50 mb-0">Accept bookings 24/7 with our TREEP Agent Portal.</p>
                        </div>
                    </div>
                    <!--end col-->

                    <div class="col-md-4 mt-4 mt-sm-0">
                      <div class="text-md-right text-center">
                            <a href="https://treep.cc/demo/?channelType=1" class="btn btn-light" target="_blank">Book a meeting</a>
                        </div>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- End -->


    <Footer />
    <!-- Footer End -->
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>

<style>
.modal-content {
  background-color: transparent;
}
</style>
